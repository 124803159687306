<template>
  <div>
    <div v-if="debtorData.result_kvi.length">
      <v-card
        v-for="(resultKvi, index) in debtorData.result_kvi"
        :key="resultKvi.orderBank"
        elevation="1"
        :class="{ 'mt-3': index !== 0 }">
        <v-card-text>
          <CustomExpansionPanel
            :label="`Результат пошуку №${resultKvi.orderBank}`">
            <template #default>
              <div>
                <TableValue title="Ідентифікаційні дані боржника">
                  <template #field>
                    <div v-if="resultKvi.nameUr">
                      <ItemValue
                        v-for="(item, index) in getResultKviCompany(resultKvi)"
                        :key="index"
                        :label="item.label"
                        :coefficient="item.coefficient"
                        :directory="item.directory"
                        :value="item.value">
                      </ItemValue>
                    </div>
                    <div v-if="resultKvi.fio">
                      <ItemValue
                        v-for="(item, index) in getResultKviPerson(resultKvi)"
                        :key="index"
                        :label="item.label"
                        :coefficient="item.coefficient"
                        :directory="item.directory"
                        :value="item.value">
                      </ItemValue>
                    </div>
                  </template>
                </TableValue>
                <CustomExpansionPanel>
                  <template #label>
                    <h4>
                      Кредитні операції та виконання зобов’язань за кредитними
                      операціями
                    </h4>
                  </template>
                  <template #default>
                    <CustomExpansionPanel
                      v-for="(credit, creditIdx) in resultKvi.credits"
                      :key="creditIdx"
                      border>
                      <template #label>
                        <h4>
                          {{
                            $directory
                              .get('nbuCreditTypes', null, {
                                type: 'collection',
                              })
                              .getItem(credit.typeCredit)
                              ?.name.replace(/^./, char => char.toUpperCase())
                          }}
                        </h4>
                      </template>
                      <template #default>
                        <div>
                          <TableValue>
                            <template #field>
                              <div class="d-flex flex-column gap-1">
                                <ItemValue
                                  label="Номер договору"
                                  :value="credit.numberDog">
                                </ItemValue>
                                <ItemValue
                                  label="Дата укладання договору"
                                  :value="credit.dogDay">
                                </ItemValue>
                                <ItemValue
                                  label="Кінцева дата погашення кредиту/наданого фінансового зобов’язання"
                                  :value="credit.endDay">
                                </ItemValue>
                                <ItemValue
                                  label="Загальна сума (ліміт кредитної лінії/овердрафту) наданого фінансового зобов’язання"
                                  :value="credit.sumZagal"
                                  :coefficient="10 ** 2">
                                </ItemValue>
                                <ItemValue
                                  label="Валюта"
                                  :value="credit.r030"
                                  directory="R030">
                                </ItemValue>
                                <!-- table -->
                                <v-divider class="my-2"></v-divider>
                                <ItemValue
                                  is-table
                                  :items="[
                                    {
                                      label: 'Строкова заборгованість',
                                      valueMain: credit.sumArrearsBase,
                                      valueInterest: credit.sumArrearsProc,
                                      coefficient: 10 ** 2,
                                    },
                                    {
                                      label: 'Прострочена заборгованість',
                                      valueMain: credit.arrearBase,
                                      valueInterest: credit.arrearProc,
                                      coefficient: 10 ** 2,
                                    },
                                    {
                                      label: 'Кількість днів прострочення',
                                      valueMain: credit.dayBase,
                                      valueInterest: credit.dayProc,
                                    },
                                  ]">
                                </ItemValue>
                              </div>
                            </template>
                          </TableValue>
                          <div>
                            <CustomExpansionPanel v-if="credit.tranche.length">
                              <template #label>
                                <h4>Транші ({{ credit.tranche.length }})</h4>
                              </template>
                              <template #default>
                                <CustomExpansionPanel
                                  v-for="(
                                    tranche, trancheIndex
                                  ) in credit.tranche"
                                  :key="trancheIndex"
                                  border>
                                  <template #label>
                                    <h4>Транш №{{ trancheIndex + 1 }}</h4>
                                  </template>
                                  <template #default>
                                    <div class="d-flex flex-column gap-1">
                                      <ItemValue
                                        label="Номер договору"
                                        :value="tranche.numDogTr">
                                      </ItemValue>
                                      <ItemValue
                                        label="Дата укладання договору траншу"
                                        :value="tranche.dogDayTr">
                                      </ItemValue>
                                      <ItemValue
                                        label="Кінцева дата погашення заборгованості траншу"
                                        :value="tranche.endDayTr">
                                      </ItemValue>
                                      <ItemValue
                                        label="Сума наданого фінансового зобов’язання за траншем"
                                        :value="tranche.sumZagalTr"
                                        :coefficient="10 ** 2">
                                      </ItemValue>
                                      <ItemValue
                                        label="Валюта"
                                        :value="tranche.r030Tr"
                                        directory="R030">
                                      </ItemValue>
                                      <!-- table -->
                                      <v-divider class="my-2"></v-divider>
                                      <ItemValue
                                        is-table
                                        :items="[
                                          {
                                            label: 'Строкова заборгованість',
                                            valueMain: tranche.sumArrearsTrBase,
                                            valueInterest:
                                              tranche.sumArrearsTrProc,
                                            coefficient: 10 ** 2,
                                          },
                                          {
                                            label: 'Прострочена заборгованість',
                                            valueMain: tranche.arrearBaseTr,
                                            valueInterest: tranche.arrearProcTr,
                                            coefficient: 10 ** 2,
                                          },
                                          {
                                            label:
                                              'Кількість днів прострочення',
                                            valueMain: tranche.dayBaseTr,
                                            valueInterest: tranche.dayProcTr,
                                          },
                                        ]">
                                      </ItemValue>
                                    </div>
                                  </template>
                                </CustomExpansionPanel>
                              </template>
                            </CustomExpansionPanel>
                            <h4 v-else class="mt-2">Транші (0)</h4>
                          </div>
                        </div>
                      </template>
                    </CustomExpansionPanel>
                  </template>
                </CustomExpansionPanel>
                <div>
                  <CustomExpansionPanel v-if="resultKvi.pledge.length">
                    <template #label>
                      <h4>Застава</h4>
                    </template>
                    <template #default>
                      <v-data-table
                        :items="resultKvi.pledge"
                        :headers="debtorPledgeHeaders"
                        item-value="orderZastava">
                        <template #item.s031="{ item }">
                          <span v-if="item.s031">
                            {{
                              directory.S031.getItem(item.s031)?.name ||
                              item.s031
                            }}
                          </span>
                          <span v-else class="text-disabled">Не вказано</span>
                        </template>
                        <template #item.pledgeDay="{ item }">
                          <span v-if="item.pledgeDay">
                            {{ new CustomDate(item.pledgeDay).toString() }}
                          </span>
                          <span v-else class="text-disabled">Не вказано</span>
                        </template>
                      </v-data-table>
                    </template>
                  </CustomExpansionPanel>
                  <h4 v-else>Застава (0)</h4>
                </div>
              </div>
            </template>
          </CustomExpansionPanel>
        </v-card-text>
      </v-card>
    </div>
    <InfoNotFound v-else></InfoNotFound>
  </div>
</template>

<script lang="ts">
import ItemValue from '@/pages/check-debtor/components/ItemValue.vue'
import CustomExpansionPanel from '@/pages/check-debtor/components/CustomExpansionPanel.vue'
import TableValue from '@/pages/check-debtor/components/TableValue.vue'
import InfoNotFound from '@/pages/check-debtor/components/InfoNotFound.vue'
import { useDebtorResult } from '@/pages/check-debtor/single/helpers.js'
import { PropType } from 'vue'
import { DebtorData } from '@/utils/types/checkDebtor.js'
import { CustomDate, rankSpacing } from 'best-modules/utils'
import { usePackageDirectory } from '@/store/package-directory'
import { fillDirectory } from '@/directory'
import { debtorPledgeHeaders } from '@/utils/tableHeaders'

export default {
  components: { InfoNotFound, TableValue, CustomExpansionPanel, ItemValue },
  props: {
    debtorData: {
      type: Object as PropType<DebtorData>,
    },
  },
  data() {
    return {
      debtorPledgeHeaders,
    }
  },
  methods: { rankSpacing, CustomDate },
  setup() {
    const { fillDirectory: fillPackageDirectory, directory } =
      usePackageDirectory()

    fillPackageDirectory()
    fillDirectory(['nbuCreditTypes', 'debtorCheckResult'])

    return {
      directory,
      ...useDebtorResult(),
    }
  },
}
</script>

<style scoped lang="scss"></style>

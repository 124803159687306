<template>
  <div>
    <div v-if="debtorData.info_creditor.length">
      <v-card
        v-for="(infoCreditor, idx) in debtorData.info_creditor"
        :key="idx"
        elevation="1"
        :class="{ 'mt-3': idx !== 0 }">
        <v-card-text>
          <CustomExpansionPanel :label="infoCreditor.creditor">
            <template #default>
              <div>
                <TableValue title="Ідентифікаційні дані боржника">
                  <template #field>
                    <div v-if="infoCreditor.ent_person">
                      <ItemValue
                        v-for="(item, index) in ent_person(infoCreditor)"
                        :key="index"
                        :label="item.label"
                        :coefficient="item.coefficient"
                        :directory="item.directory"
                        :value="item.value">
                      </ItemValue>
                    </div>
                    <div v-if="infoCreditor.ind_person">
                      <ItemValue
                        v-for="(item, index) in ind_person(infoCreditor)"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                      </ItemValue>
                    </div>
                  </template>
                </TableValue>
                <v-divider class="my-5"></v-divider>
                <CustomExpansionPanel
                  v-for="(credit, creditIdx) in infoCreditor.credit"
                  :key="creditIdx">
                  <template #label>
                    <h4>
                      Кредитні операції та виконання зобов’язань за кредитними
                      операціями №{{ creditIdx + 1 }}
                    </h4>
                  </template>
                  <template #default>
                    <CustomExpansionPanel
                      v-for="(loan, loanIdx) in credit.loan || []"
                      :key="loanIdx"
                      border>
                      <template #label>
                        <h4>Активна операція</h4>
                      </template>
                      <template #default>
                        <TableValue>
                          <template #field>
                            <div class="d-flex flex-column gap-2">
                              <ItemValue
                                v-for="(item, index) in credit_loan(loan)"
                                :key="index"
                                :label="item.label"
                                :coefficient="item.coefficient"
                                :directory="item.directory"
                                :value="item.value">
                              </ItemValue>
                              <div v-if="loan?.collateral.length">
                                <v-divider class="my-2"></v-divider>
                                <CustomExpansionPanel
                                  v-for="(
                                    itemCollateral, indexCollateral
                                  ) in loan?.collateral"
                                  :key="indexCollateral">
                                  <template #label>
                                    <h4>
                                      Договір застави ({{
                                        loan?.collateral.length
                                      }})
                                    </h4>
                                  </template>
                                  <template #default>
                                    <CustomExpansionPanel border>
                                      <template #label>
                                        <h4>
                                          Застава №{{ indexCollateral + 1 }}
                                        </h4>
                                      </template>
                                      <template #default>
                                        <TableValue>
                                          <template #field>
                                            <div>
                                              <ItemValue
                                                label="Дата укладення договору"
                                                :value="
                                                  new CustomDate(
                                                    itemCollateral.agreem_start_date
                                                  ).toString({ time: false })
                                                ">
                                              </ItemValue>
                                              <!-- Рухоме майно   -->
                                              <div
                                                v-if="
                                                  itemCollateral.movable.length
                                                "
                                                class="mt-2">
                                                <CustomExpansionPanel>
                                                  <template #label>
                                                    <h4>
                                                      Об’єкт рухомого майна ({{
                                                        itemCollateral?.movable
                                                          .length
                                                      }})
                                                    </h4>
                                                  </template>
                                                  <template #default>
                                                    <CustomExpansionPanel
                                                      v-for="(
                                                        movable, movIdx
                                                      ) in itemCollateral.movable ||
                                                      []"
                                                      :key="movIdx"
                                                      border>
                                                      <template #label>
                                                        <h4>
                                                          Об’єкт №
                                                          {{ movIdx + 1 }}
                                                        </h4>
                                                      </template>
                                                      <template #default>
                                                        <TableValue>
                                                          <template #field>
                                                            <div
                                                              class="d-flex flex-column gap-2">
                                                              <ItemValue
                                                                label="Вид забезпечення виконання зобов'язання"
                                                                :value="
                                                                  movable.s031_col_type
                                                                "
                                                                directory="S031">
                                                              </ItemValue>
                                                              <ItemValue
                                                                label="Валюта"
                                                                :value="
                                                                  movable.r030_currency
                                                                "
                                                                directory="R030">
                                                              </ItemValue>
                                                              <ItemValue
                                                                label="Вартість забезпечення"
                                                                :value="
                                                                  movable.collateral_amount
                                                                "
                                                                :coefficient="
                                                                  10 ** 2
                                                                ">
                                                              </ItemValue>
                                                            </div>
                                                          </template>
                                                        </TableValue>
                                                      </template>
                                                    </CustomExpansionPanel>
                                                  </template>
                                                </CustomExpansionPanel>
                                              </div>
                                              <!-- нерухоме майно   -->
                                              <div
                                                v-if="
                                                  itemCollateral.immovable
                                                    .length
                                                ">
                                                <CustomExpansionPanel>
                                                  <template #label>
                                                    <h4>
                                                      Об’єкт нерухомого майна
                                                      ({{
                                                        itemCollateral
                                                          ?.immovable.length
                                                      }})
                                                    </h4>
                                                  </template>
                                                  <template #default>
                                                    <CustomExpansionPanel
                                                      v-for="(
                                                        immovable, movIdx
                                                      ) in itemCollateral.immovable ||
                                                      []"
                                                      :key="movIdx"
                                                      border>
                                                      <template #label>
                                                        <h4>
                                                          Об’єкт №{{
                                                            movIdx + 1
                                                          }}
                                                        </h4>
                                                      </template>
                                                      <template #default>
                                                        <TableValue>
                                                          <template #field>
                                                            <div
                                                              class="d-flex flex-column gap-2">
                                                              <ItemValue
                                                                label="Вид забезпечення виконання зобов'язання"
                                                                :value="
                                                                  immovable.s031_col_type
                                                                "
                                                                directory="S031">
                                                              </ItemValue>
                                                              <ItemValue
                                                                label="Валюта"
                                                                :value="
                                                                  immovable.r030_currency
                                                                "
                                                                directory="R030">
                                                              </ItemValue>
                                                              <ItemValue
                                                                label="Вартість забезпечення"
                                                                :value="
                                                                  immovable.collateral_amount
                                                                "
                                                                :coefficient="
                                                                  10 ** 2
                                                                ">
                                                              </ItemValue>
                                                            </div>
                                                          </template>
                                                        </TableValue>
                                                      </template>
                                                    </CustomExpansionPanel>
                                                  </template>
                                                </CustomExpansionPanel>
                                              </div>
                                              <!-- deposit   -->
                                              <div
                                                v-if="
                                                  itemCollateral.deposit.length
                                                ">
                                                <CustomExpansionPanel>
                                                  <template #label>
                                                    <h4>
                                                      Фінансове забезпечення ({{
                                                        itemCollateral.deposit
                                                          .length
                                                      }})
                                                    </h4>
                                                  </template>
                                                  <template #default>
                                                    <CustomExpansionPanel
                                                      v-for="(
                                                        deposit, depositIdx
                                                      ) in itemCollateral.deposit ||
                                                      []"
                                                      :key="depositIdx"
                                                      border>
                                                      <template #label>
                                                        <h4>
                                                          Об’єкт №{{
                                                            depositIdx + 1
                                                          }}
                                                        </h4>
                                                      </template>
                                                      <template #default>
                                                        <TableValue>
                                                          <template #field>
                                                            <div
                                                              class="d-flex flex-column gap-2">
                                                              <ItemValue
                                                                label="Валюта"
                                                                :value="
                                                                  deposit.r030_currency
                                                                "
                                                                directory="R030">
                                                              </ItemValue>
                                                              <ItemValue
                                                                label="Вартість забезпечення"
                                                                :value="
                                                                  deposit.collateral_amount
                                                                "
                                                                :coefficient="
                                                                  10 ** 2
                                                                ">
                                                              </ItemValue>
                                                            </div>
                                                          </template>
                                                        </TableValue>
                                                      </template>
                                                    </CustomExpansionPanel>
                                                  </template>
                                                </CustomExpansionPanel>
                                              </div>
                                            </div>
                                          </template>
                                        </TableValue>
                                      </template>
                                    </CustomExpansionPanel>
                                  </template>
                                </CustomExpansionPanel>
                                <v-divider class="my-2"></v-divider>
                              </div>
                              <div v-if="loan?.tranche.length">
                                <CustomExpansionPanel>
                                  <template #label>
                                    <h4>Транші ({{ loan?.tranche.length }})</h4>
                                  </template>
                                  <template #default>
                                    <CustomExpansionPanel
                                      v-for="(
                                        tranche, trancheIdx
                                      ) in loan?.tranche || []"
                                      :key="trancheIdx"
                                      border>
                                      <template #label>
                                        <h4>Транш №{{ trancheIdx + 1 }}</h4>
                                      </template>
                                      <template #default>
                                        <ItemValue
                                          v-for="(
                                            trancheItem, trancheItemIndex
                                          ) in credit_tranche(tranche)"
                                          :key="trancheItemIndex"
                                          :label="trancheItem.label"
                                          :coefficient="trancheItem.coefficient"
                                          :directory="trancheItem.directory"
                                          :value="trancheItem.value">
                                        </ItemValue>
                                      </template>
                                    </CustomExpansionPanel>
                                  </template>
                                </CustomExpansionPanel>
                                <v-divider class="my-2"></v-divider>
                              </div>
                            </div>
                          </template>
                        </TableValue>
                      </template>
                    </CustomExpansionPanel>
                    <CustomExpansionPanel v-if="credit.liability" border>
                      <template #label>
                        <h4>Фінансове зобов'язання</h4>
                      </template>
                      <template #default>
                        <TableValue>
                          <template #field>
                            <div class="d-flex flex-column gap-2">
                              <ItemValue
                                v-for="(item, index) in credit_liability(
                                  credit
                                )"
                                :key="index"
                                :label="item.label"
                                :coefficient="item.coefficient"
                                :directory="item.directory"
                                :value="item.value">
                              </ItemValue>
                              <div v-if="credit.liability?.collateral.length">
                                <v-divider class="my-2"></v-divider>
                                <CustomExpansionPanel
                                  v-for="(
                                    itemCollateral, indexCollateral
                                  ) in credit.liability?.collateral"
                                  :key="indexCollateral">
                                  <template #label>
                                    <h4>
                                      Договір застави ({{
                                        itemCollateral?.collateral.length
                                      }})
                                    </h4>
                                  </template>
                                  <template #default>
                                    <CustomExpansionPanel border>
                                      <template #label>
                                        <h4>
                                          Застава №{{ indexCollateral + 1 }}
                                        </h4>
                                      </template>
                                      <template #default>
                                        <TableValue>
                                          <template #field>
                                            <div>
                                              <ItemValue
                                                label="Дата укладення договору"
                                                :value="
                                                  new CustomDate(
                                                    itemCollateral.agreem_start_date
                                                  ).toString({ time: false })
                                                ">
                                              </ItemValue>
                                              <!-- Рухоме майно   -->
                                              <div
                                                v-if="
                                                  itemCollateral.movable.length
                                                "
                                                class="mt-2">
                                                <CustomExpansionPanel>
                                                  <template #label>
                                                    <h4>
                                                      Об’єкт рухомого майна ({{
                                                        itemCollateral?.movable
                                                          .length
                                                      }})
                                                    </h4>
                                                  </template>
                                                  <template #default>
                                                    <CustomExpansionPanel
                                                      v-for="(
                                                        movable, movIdx
                                                      ) in itemCollateral.movable ||
                                                      []"
                                                      :key="movIdx"
                                                      border>
                                                      <template #label>
                                                        <h4>
                                                          Об’єкт №
                                                          {{ movIdx + 1 }}
                                                        </h4>
                                                      </template>
                                                      <template #default>
                                                        <TableValue>
                                                          <template #field>
                                                            <div
                                                              class="d-flex flex-column gap-2">
                                                              <ItemValue
                                                                label="Вид забезпечення виконання зобов'язання"
                                                                :value="
                                                                  movable.s031_col_type
                                                                "
                                                                directory="S031">
                                                              </ItemValue>
                                                              <ItemValue
                                                                label="Валюта"
                                                                :value="
                                                                  movable.r030_currency
                                                                "
                                                                directory="R030">
                                                              </ItemValue>
                                                              <ItemValue
                                                                label="Вартість забезпечення"
                                                                :value="
                                                                  movable.collateral_amount
                                                                "
                                                                :coefficient="
                                                                  10 ** 2
                                                                ">
                                                              </ItemValue>
                                                            </div>
                                                          </template>
                                                        </TableValue>
                                                      </template>
                                                    </CustomExpansionPanel>
                                                  </template>
                                                </CustomExpansionPanel>
                                              </div>
                                              <!-- нерухоме майно   -->
                                              <div
                                                v-if="
                                                  itemCollateral.immovable
                                                    .length
                                                ">
                                                <CustomExpansionPanel>
                                                  <template #label>
                                                    <h4>
                                                      Об’єкт нерухомого майна
                                                      ({{
                                                        itemCollateral
                                                          ?.immovable.length
                                                      }})
                                                    </h4>
                                                  </template>
                                                  <template #default>
                                                    <CustomExpansionPanel
                                                      v-for="(
                                                        immovable, movIdx
                                                      ) in itemCollateral.immovable ||
                                                      []"
                                                      :key="movIdx"
                                                      border>
                                                      <template #label>
                                                        <h4>
                                                          Об’єкт №{{
                                                            movIdx + 1
                                                          }}
                                                        </h4>
                                                      </template>
                                                      <template #default>
                                                        <TableValue>
                                                          <template #field>
                                                            <div
                                                              class="d-flex flex-column gap-2">
                                                              <ItemValue
                                                                label="Вид забезпечення виконання зобов'язання"
                                                                :value="
                                                                  immovable.s031_col_type
                                                                "
                                                                directory="S031">
                                                              </ItemValue>
                                                              <ItemValue
                                                                label="Валюта"
                                                                :value="
                                                                  immovable.r030_currency
                                                                "
                                                                directory="R030">
                                                              </ItemValue>
                                                              <ItemValue
                                                                label="Вартість забезпечення"
                                                                :value="
                                                                  immovable.collateral_amount
                                                                "
                                                                :coefficient="
                                                                  10 ** 2
                                                                ">
                                                              </ItemValue>
                                                            </div>
                                                          </template>
                                                        </TableValue>
                                                      </template>
                                                    </CustomExpansionPanel>
                                                  </template>
                                                </CustomExpansionPanel>
                                              </div>
                                              <!-- deposit   -->
                                              <div
                                                v-if="
                                                  itemCollateral.deposit.length
                                                ">
                                                <CustomExpansionPanel>
                                                  <template #label>
                                                    <h4>
                                                      Фінансове забезпечення ({{
                                                        itemCollateral.deposit
                                                          .length
                                                      }})
                                                    </h4>
                                                  </template>
                                                  <template #default>
                                                    <CustomExpansionPanel
                                                      v-for="(
                                                        deposit, depositIdx
                                                      ) in itemCollateral.deposit ||
                                                      []"
                                                      :key="depositIdx"
                                                      border>
                                                      <template #label>
                                                        <h4>
                                                          Об’єкт №{{
                                                            depositIdx + 1
                                                          }}
                                                        </h4>
                                                      </template>
                                                      <template #default>
                                                        <TableValue>
                                                          <template #field>
                                                            <div
                                                              class="d-flex flex-column gap-2">
                                                              <ItemValue
                                                                label="Валюта"
                                                                :value="
                                                                  deposit.r030_currency
                                                                "
                                                                directory="R030">
                                                              </ItemValue>
                                                              <ItemValue
                                                                label="Вартість забезпечення"
                                                                :value="
                                                                  deposit.collateral_amount
                                                                "
                                                                :coefficient="
                                                                  10 ** 2
                                                                ">
                                                              </ItemValue>
                                                            </div>
                                                          </template>
                                                        </TableValue>
                                                      </template>
                                                    </CustomExpansionPanel>
                                                  </template>
                                                </CustomExpansionPanel>
                                              </div>
                                            </div>
                                          </template>
                                        </TableValue>
                                      </template>
                                    </CustomExpansionPanel>
                                  </template>
                                </CustomExpansionPanel>
                                <v-divider class="my-2"></v-divider>
                              </div>
                              <div v-if="credit.liability?.tranche.length">
                                <CustomExpansionPanel>
                                  <template #label>
                                    <h4>
                                      Транші ({{
                                        credit.liability?.tranche.length
                                      }})
                                    </h4>
                                  </template>
                                  <template #default>
                                    <CustomExpansionPanel
                                      v-for="(tranche, trancheIdx) in credit
                                        .liability.tranche || []"
                                      :key="trancheIdx"
                                      border>
                                      <template #label>
                                        <h4>Транш №{{ trancheIdx + 1 }}</h4>
                                      </template>
                                      <template #default>
                                        <ItemValue
                                          v-for="(
                                            trancheItem, trancheItemIndex
                                          ) in credit_tranche(tranche)"
                                          :key="trancheItemIndex"
                                          :label="trancheItem.label"
                                          :coefficient="trancheItem.coefficient"
                                          :directory="trancheItem.directory"
                                          :value="trancheItem.value">
                                        </ItemValue>
                                      </template>
                                    </CustomExpansionPanel>
                                  </template>
                                </CustomExpansionPanel>
                                <v-divider class="my-2"></v-divider>
                              </div>
                            </div>
                          </template>
                        </TableValue>
                      </template>
                    </CustomExpansionPanel>
                  </template>
                </CustomExpansionPanel>
              </div>
            </template>
          </CustomExpansionPanel>
        </v-card-text>
      </v-card>
    </div>
    <InfoNotFound v-else></InfoNotFound>
  </div>
</template>

<script lang="ts">
import ItemValue from '@/pages/check-debtor/components/ItemValue.vue'
import CustomExpansionPanel from '@/pages/check-debtor/components/CustomExpansionPanel.vue'
import TableValue from '@/pages/check-debtor/components/TableValue.vue'
import InfoNotFound from '@/pages/check-debtor/components/InfoNotFound.vue'
import { PropType } from 'vue'
import { DebtorData } from '@/utils/types/checkDebtor.js'
import { useDebtorResult } from '@/pages/check-debtor/single/helpers.js'
import { CustomDate, rankSpacing } from 'best-modules/utils'
import { usePackageDirectory } from '@/store/package-directory'
import { fillDirectory } from '@/directory'
import { debtorPledgeHeaders } from '@/utils/tableHeaders'

export default {
  components: { InfoNotFound, TableValue, CustomExpansionPanel, ItemValue },
  props: {
    debtorData: {
      type: Object as PropType<DebtorData>,
    },
  },
  data() {
    return {
      debtorPledgeHeaders,
    }
  },
  methods: { rankSpacing, CustomDate },
  setup() {
    const { fillDirectory: fillPackageDirectory, directory } =
      usePackageDirectory()

    fillPackageDirectory()
    fillDirectory(['nbuCreditTypes', 'debtorCheckResult'])

    return {
      directory,
      ...useDebtorResult(),
    }
  },
}
</script>

<style scoped lang="scss"></style>

<template>
  <div>
    <b-section-loader
      :loading="$loading.isLoading(['fillPackageDirectory', 'nbuCreditTypes'])">
      <div v-if="debtorData.status === 'Found'">
        <credit-version1
          v-if="toggleTab === 'cr-version-1'"
          :debtor-data="debtorData"></credit-version1>
        <credit-version2
          v-if="toggleTab === 'cr-version-2'"
          :debtor-data="debtorData"></credit-version2>
      </div>

      <div v-if="debtorData.status === 'NotFound'">
        <InfoNotFound></InfoNotFound>
      </div>
      <div v-if="debtorData.status === 'LegallyWithheld'">
        Знайдена інформація не може бути надана у зв’язку із встановленими
        законодавством обмеженнями
      </div>
    </b-section-loader>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import { BSectionLoader } from 'best-modules/components'
import { usePackageDirectory } from '@/store/package-directory'
import { DebtorData } from '@/utils/types/checkDebtor'
import { useDebtorResult } from './helpers'
import { fillDirectory } from '@/directory'
import InfoNotFound from '@/pages/check-debtor/components/InfoNotFound.vue'
import CreditVersion1 from '@/pages/check-debtor/single/tabs/credit-version-1.vue'
import CreditVersion2 from '@/pages/check-debtor/single/tabs/credit-version-2.vue'

export default {
  name: 'CheckDebtorResult',
  components: {
    CreditVersion2,
    CreditVersion1,
    InfoNotFound,
    BSectionLoader,
  },
  props: {
    debtorData: {
      type: Object as PropType<DebtorData>,
    },
    toggleTab: {
      type: String,
    },
  },
  setup() {
    const { fillDirectory: fillPackageDirectory, directory } =
      usePackageDirectory()

    fillPackageDirectory()
    fillDirectory(['nbuCreditTypes', 'debtorCheckResult'])

    return {
      directory,
      ...useDebtorResult(),
    }
  },
}
</script>

<style scoped lang="scss"></style>
